@import '../variables.scss';

.root {
  height: 100vh;
  width: $side-bar-width;
  position: fixed;
  left: 0;
  top: $nav-bar-height;
  z-index: $second-most;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $backgroundColor;
  backdrop-filter: blur(5px);
  box-shadow: $boxShadow;
  @media (max-width: 1024px) {
    display: none;
  }
}
