@import '../variables.scss';

.root {
  min-width: 160px;
  border-radius: $borderRadius;
  padding: 1rem 2rem;
  border: none;
  background-image: linear-gradient(-90deg, $lapis-lazuli, $steel-blue);
  box-shadow: 0 0 0 0, 0 0 0 0 $xiketic, 0 0 transparent;
  outline: none;
  cursor: pointer;
  color: $nyanza;
  text-transform: uppercase;
  font-size: 1rem;
  @media (max-width: 1024px) {
    padding: 0.5rem;
    min-width: 75px;
    font-size: 0.75rem;
  }
}
