@import '../variables.scss';

html,
body {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

.root {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  background-color: $xiketic;
  font-family: $font-family;
  color: $nyanza;
  background: linear-gradient(0deg, $cafe-au-lait, $green-cyan),
    linear-gradient(120deg, $lapis-lazuli, $green-cyan),
    linear-gradient(240deg, $cafe-au-lait, $steel-blue);
}

.body {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  min-height: 100vh;
}

.content {
  width: 100%;
  position: relative;
  margin-left: $side-bar-width;
  margin-top: $nav-bar-height;
  @media (max-width: 1024px) {
    margin-left: 0;
  }
}

.row {
  display: grid;
  grid-gap: 2rem;
  margin: 2rem;
  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin: 1rem;
  }
}

.racingBarChart {
  grid-column: 1 / 4;
  grid-row: 1 / 12;
}

.github {
  grid-column: 4 / 6;
  grid-row: 11 / 12;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.lineChart {
  grid-column: 2 / 6;
}

.underConstruction {
  grid-row: 1 / 11;
}
