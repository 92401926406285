@import '../../variables.scss';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  box-sizing: border-box;
  font-family: $font-family;
  position: relative;
}

.body {
  padding: 2rem;
  overflow: hidden;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

svg {
  overflow: visible;
  display: block;
  width: 100%;
  height: 300px;
  transition: all 1s ease-in-out;
}

.radioButtonGroup {
  margin-top: 1rem;
  position: relative;
  width: fit-content;
  transition: all 0.5s ease-in-out 0.1s;
}

.country {
  margin: 0;
  border-radius: 0;
  transition: all 0.5s ease-in-out 0.1s;

  &:first-of-type {
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
  }

  &:last-of-type {
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
  }
}

.slider {
  position: absolute;
  height: 100%;
  top: 0;
  width: 33.33%;
  border: 1px solid;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out 0.1s;
}

.slider.vietnam {
  left: 1px;
  border-radius: $borderRadius 0 0 $borderRadius ;
}

.slider.japan {
  left: calc(33.33% - 1px);
  border-radius: 0;
}

.slider.spain {
  left: calc(66.66% - 1px);
  border-radius: 0 $borderRadius $borderRadius 0;
}

.labels {
  position: absolute;
  top: 12rem;
  left: 6rem;

  .labelWrapper {
    display: flex;
    align-items: center;

    .label {
      margin-left: 1rem;
    }
  }
}

.chart {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.showLoadingIndicator {
  background: rgba($color: #000000, $alpha: 0.1);
  width: 100%;
  height: 100%;
  .loadingIndicator {
    visibility: visible;
  }
}

.hideLoadingIndicator {
  .loadingIndicator {
    visibility: hidden;
  }
}

.loadingIndicator {
  position: absolute;
  &:before {
    content: 'Loading...';
    display: block;
    font-size: 2rem;
  }
}