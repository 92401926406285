@import '../variables.scss';

.root {
  display: flex;
  height: $nav-bar-height;
  width: 100%;
  position: fixed;
  background-color: $backgroundColor;
  backdrop-filter: blur(5px);
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  z-index: $top-most;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding: 0 2rem;
  box-sizing: border-box;
}
