@import '../../variables.scss';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
  font-family: $font-family;
}

.currentMonth {
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
}

.body {
  margin: 1rem 0;
}

svg {
  overflow: visible;
  display: block;
  width: 100%;
  height: 300px;
  transition: all 1s ease-in-out;
}

.footer{
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}